import { GameLogicCharacter } from "@types"
import React, { useState } from "react"

type InputAvatar = GameLogicCharacter["avatar"]

type EditedAvatarContextType = {
    editedAvatar: InputAvatar
    setEditedAvatar: (avatar: InputAvatar | ((prev: InputAvatar) => InputAvatar)) => void
  }
  
  const EditedAvatarContext = React.createContext<EditedAvatarContextType | undefined>(undefined)
  
  export const useEditedAvatar = () => {
    const context = React.useContext(EditedAvatarContext)
    if (context === undefined) {
      throw new Error('useEditedAvatar must be used within an EditedAvatarProvider')
    }
    return context
  }
  
  export const EditedAvatarProvider: React.FC<{data: InputAvatar, children: React.ReactNode }> = ({
    data,
    children,
  }) => {
    const [editedAvatar, setEditedAvatar] = useState<InputAvatar>(data);
  
    return (
      <EditedAvatarContext.Provider value={{ editedAvatar, setEditedAvatar }}>
        {children}
      </EditedAvatarContext.Provider>
    )
  }
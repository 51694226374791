import { input } from "@testing-library/user-event/dist/cjs/event/input.js"
import type {
  BaseSelection,
  GameLogicContent,
  GameLogicRequirements,
  GameLogicState,
  GameLogicTypes,
} from "@types"

// Function to check if the current value meets the given requirement (gt, lt, eq, not)
const meetsRequirement = (
  value: number,
  requirement?: GameLogicTypes,
): boolean => {
  if (requirement == undefined) return true
  if ("gt" in requirement && value < requirement.gt) return false
  if ("lt" in requirement && value > requirement.lt) return false
  if ("eq" in requirement && value !== requirement.eq) return false
  if ("not" in requirement && value === requirement.not) return false
  if (
    "gt" in requirement &&
    "lt" in requirement &&
    (value < requirement.gt || value > requirement.lt)
  )
    return false

  return true
}

// Function to check tag requirements
const meetsTagRequirements = (
  tags: string[],
  requirementTags?: {
    not?: string[]
    or?: string[]
    and?: string[]
    has?: string
  },
) => {
  if (!requirementTags) return true
  if (
    requirementTags.not &&
    requirementTags.not.some(tag => tags.includes(tag))
  )
    return false
  if (
    requirementTags.and &&
    !requirementTags.and.every(tag => tags.includes(tag))
  )
    return false
  if (requirementTags.or && !requirementTags.or.some(tag => tags.includes(tag)))
    return false
  if (requirementTags.has && !tags.includes(requirementTags.has)) return false

  return true
}

const processRequirements = (
  requirements: GameLogicRequirements | undefined,
  currentState: GameLogicState,
) => {
  const { age, education, health, relationship, tags } = currentState
  if (requirements) {
    if (!meetsRequirement(age, requirements.age)) return false
    if (!meetsRequirement(education, requirements.education)) return false
    if (!meetsRequirement(health, requirements.health)) return false
    if (!meetsRequirement(relationship, requirements.relationship)) return false
    if (!meetsTagRequirements(tags, requirements.tags)) return false
  }
  return true
}

export const getValidTriggers = (
  input: GameLogicContent,
  currentState: GameLogicState,
) => {
  const allValidTriggers = input.flatMap(deck =>
    deck.groups.flatMap(group => {
      const { requirements, slides } = group
      const isValidEntry = processRequirements(requirements, currentState)
      if (isValidEntry) {
        return slides
          ?.filter(trigger => trigger.type === "trigger")
          .filter(trigger =>
            processRequirements(trigger.requirements, currentState),
          )
      }
      return []
    }),
  )
  const allTitles = currentState.history.map((h) => (h.slide)).filter((s) => s !== undefined)
  return allValidTriggers.filter(t => t !== undefined && !allTitles.includes(t.title.en))
}

// Main Function to Filter and Select a Slide
export const getValidSlides = (
  decks: GameLogicContent,
  currentState: GameLogicState,
) => {
  // Filter all slides that meet the requirements
  const allValidSlides = decks.flatMap(deck =>
    deck.groups.flatMap(entry => {
      const { requirements, slides } = entry
      const isValidEntry = processRequirements(requirements, currentState)
      if (isValidEntry) {
        return slides
          .filter(slide => slide.type !== "trigger")
          .filter(slide =>
            processRequirements(slide.requirements, currentState),
          )
      }
      return []
    }),
  )
  const allTitles = currentState.history.map((h) => (h.slide)).filter((s) => s !== undefined)
  return allValidSlides.filter(slides => slides !== undefined && !allTitles.includes(slides.title.en))
}

export const getValidRandomTrigger = (
  input: GameLogicContent,
  currentState: GameLogicState,
) => {
  const allValidTriggers = getValidTriggers(input, currentState)

  // Randomly select one slide from the valid Triggers
  if (allValidTriggers.length === 0) return null

  console.log(
    "Found Triggers",
    allValidTriggers.map(trigger => trigger?.title?.en),
  )
  const randomIndex = crypto.getRandomValues(new Uint32Array(1))[0] % allValidTriggers.length
  return allValidTriggers[randomIndex]
}

export const getRandomValidSlide = (
  input: GameLogicContent,
  currentState: GameLogicState,
) => {
  const allValidSlides = getValidSlides(input, currentState)

  // Randomly select one slide from the valid slides
  if (allValidSlides.length === 0) return null

  console.log(
    "Found Slides",
    allValidSlides.map(slides => slides?.title?.en),
  )
  const randomIndex = crypto.getRandomValues(new Uint32Array(1))[0] % allValidSlides.length
  return allValidSlides[randomIndex]
}

export const getRandomValidTriggerOrSlide = (
  input: GameLogicContent,
  currentState: GameLogicState,
) => {
  const trigger = getValidRandomTrigger(input, currentState)
  if (trigger) return trigger
  return getRandomValidSlide(input, currentState)
}

// Check game rules
export const checkGameRules = (
  currentState: GameLogicState,
  rules: GameLogicRequirements,
) => {
  const { age, education, health, relationship } = currentState
  if (!meetsRequirement(age, rules.age)) return false
  if (!meetsRequirement(education, rules.education)) return false
  if (!meetsRequirement(health, rules.health)) return false
  if (!meetsRequirement(relationship, rules.relationship)) return false
  return true
}

// Check selection consquences
export const processTurn = (
  currentState: GameLogicState,
  selection: BaseSelection,
) => {
  const newState = { ...currentState }
  if (selection.effects) {
    if (selection.effects.age) newState.age += selection.effects.age
    if (selection.effects.education)
      newState.education += selection.effects.education
    if (selection.effects.health) newState.health += selection.effects.health
    if (selection.effects.relationship)
      newState.relationship += selection.effects.relationship
  }

  if (selection.effects?.tags) {
    if (selection.effects.tags.add) {
      newState.tags = [...newState.tags, ...selection.effects.tags.add]
    }
    if (selection.effects.tags.remove) {
      newState.tags = newState.tags.filter(
        tag => !selection?.effects?.tags?.remove?.includes(tag),
      )
    }
  }

  newState.history = [ ...currentState?.history ?? [], {slide: currentState.slide?.title.en ?? 'unknown', action: selection.title.en} ]

  console.log("New State", JSON.stringify(newState.history?.map((h) => (h.slide))))
  return newState
}

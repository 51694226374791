import { Icon } from "@chakra-ui/react"
import { useAppSelector } from "../../app/hooks"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameAgency } from "./gameSlice"
import { FaPerson } from "react-icons/fa6"

export const AgencyMeter = () => {
  const turnAgency = useAppSelector(selectGameAgency)
  const { agencyModifier } = useGameTriggerContext()

  return (
    <MeterBar
      label="Agency"
      value={turnAgency}
      max={100}
      icon={<Icon mt={2} boxSize={8} as={FaPerson} />}
      modifier={agencyModifier}
    />
  )
}

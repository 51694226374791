import { Button, Stack, Image, Box } from "@chakra-ui/react"
import defaultGameData from "../default"
import type { BaseSelection } from "@types"
import { GameText } from "../../components/GameText"
import { GameHeader } from "../../components/GameHeader"
import { motion } from "framer-motion"
import { lookupGameIcon } from "../../components/GameIcon"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import {
  checkGameRules,
  getRandomValidTriggerOrSlide,
  processTurn,
} from "../../app/gameLogic"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  incrementGameTurn,
  resetGame,
  selectSlide,
  updateGame,
  updateGameSlide,
  updateAddGameTags,
  updateRemoveGameTags,
  appendHistory,
} from "./gameSlice"
import { useEffect, useState } from "react"

const SlideViewer: React.FC = () => {
  const [slideRandomIndex, setSlideRandomIndex] = useState(0)
  const currentSlide = useAppSelector(selectSlide)
  const data = currentSlide ?? defaultGameData.content[0].groups[0].slides[0]

  const slide = currentSlide ?? data

  useEffect(() => {
    setSlideRandomIndex(Math.floor(Math.random() * 6))
  }, [slide])

  if (!slide) {
    return null
  }

  return (
    <Box>
      {/* Image with aspect ratio 1, centered, not cropped */}
      <Box
        position="relative"
        width="100%"
        paddingTop="100%" // This creates a square aspect ratio
      >
        <Image
          src={slide.image}
          alt={slide.imageAlt}
          objectFit="contain"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
        />
        {/* Overlay Game Header and Text over bottom third */}
        <Box
          position="absolute"
          bottom={0}
          left={0}
          width="100%"
          bg="rgba(0, 0, 0, 0.8)" // Semi-transparent background
          color="white"
          p={4}
        >
          <GameHeader text={data.title} />
          <GameText text={data.description} />
        </Box>
      </Box>
      {/* SlideButtonGroup below the image, full width */}
      <Box width="100%" mt={4}>
        <SlideButtonGroup
          index={slideRandomIndex}
          selections={slide.selections}
        />
      </Box>
    </Box>
  )
}

const SlideButtonGroup = (props: {
  index: number
  selections: BaseSelection[]
}) => {
  if (props.selections.length === 0) {
    return (
      <Box w="100%">
        <ResetButton />
      </Box>
    )
  }

  return (
    <Stack
      spacing={4}
      mt={6}
      width="100%"
      direction={{ base: "column", md: "row" }}
      wrap="wrap"
      justifyContent="center"
    >
      {props.selections.map((selection, index) => (
        <SlideButton
          key={index}
          index={index + props.index}
          action={selection}
        />
      ))}
    </Stack>
  )
}

export const ResetButton = () => {
  const dispatch = useAppDispatch()

  const onReset = () => {
    dispatch(resetGame())
  }

  return (
    <Button onClick={onReset} colorScheme="red" mt="4" w="100%">
      Start Over
    </Button>
  )
}

const SlideButton = (props: { index: number; action: BaseSelection }) => {
  // Dispatch
  const dispatch = useAppDispatch()
  const gameData = useAppSelector(state => state.game)

  const icon = lookupGameIcon(props.action?.icon)
  const { setEducationModifier } = useGameTriggerContext()
  const { setHealthModifier } = useGameTriggerContext()
  const { setRelationshipModifier } = useGameTriggerContext()
  const { setAgencyModifier } = useGameTriggerContext()

  const [color, setColor] = useState("gray")

  useEffect(() => {
    const colors = ["red", "orange", "green", "blue", "purple", "pink"]
    const defaultColor = colors[props.index % colors.length]
    setColor(defaultColor)
  }, [props.index])

  const onClick = () => {
    onHoverEnd();
    if (gameData.slide?.title.en != null && gameData.age !== 0) {
      dispatch(
        appendHistory({
          slide: gameData.slide?.title.en,
          action: props.action.title.en,
        }),
      )
    }

    const turnEffects = gameData.effects
    dispatch(incrementGameTurn())
    dispatch(updateGame(turnEffects))

    console.log(
      "Turn Effects",
      JSON.stringify({ ...turnEffects, ...props.action.effects }),
    )

    if (props.action.effects?.agency) {
      dispatch(updateGame({ agency: props.action.effects.agency }))
    }

    if (props.action.effects?.education) {
      dispatch(updateGame({ education: props.action.effects.education }))
    }

    if (props.action.effects?.health) {
      dispatch(updateGame({ health: props.action.effects.health }))
    }

    if (props.action.effects?.relationship) {
      dispatch(updateGame({ relationship: props.action.effects.relationship }))
    }

    if (props.action.effects?.tags?.add) {
      for (const tag of props.action.effects.tags.add) {
        dispatch(updateAddGameTags(tag))
      }
    }

    if (props.action.effects?.tags?.remove) {
      for (const tag of props.action.effects.tags.remove) {
        dispatch(updateRemoveGameTags(tag))
      }
    }

    const updatedGameData = processTurn(gameData, props.action)
    console.log("Updated stats", updatedGameData)
    const isGameOver = !checkGameRules(updatedGameData, defaultGameData.rules)
    if (isGameOver) {
      console.log("Game over")
      const journeyText = gameData.history.map(o => `${o.slide}, ${o.action}`).join("\n")
      console.log("Journey", journeyText);
      dispatch(
        updateGameSlide({
          title: { en: `What an adventure!` },
          description: {
            en: 'Try again to see where you end up next time.',
          },
          image: "/default_slide.webp",
          imageAlt: "slide alt text",
          type: "event",
          selections: [],
        }),
      )
      return
    }

    const newSlide = getRandomValidTriggerOrSlide(
      defaultGameData.content,
      updatedGameData,
    )
    if (newSlide) {
      dispatch(updateGameSlide(newSlide))
    } else {
      console.log("No new slide found")
    }
  }

  const onHoverStart = () => {
    if (!props.action.effects?.agency) {
      setAgencyModifier(25)
    }
    if (props.action?.effects?.education) {
      setEducationModifier(25)
    }
    if (props.action?.effects?.health) {
      setHealthModifier(25)
    }
    if (props.action?.effects?.relationship) {
      setRelationshipModifier(25)
    }
  }

  const onHoverEnd = () => {
    setAgencyModifier(0)
    setEducationModifier(0)
    setHealthModifier(0)
    setRelationshipModifier(0)
  }

  return (
    <Box w="100%">
      <motion.a
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
        onTouchStartCapture={onHoverStart}
        onTouchEndCapture={onHoverEnd}
        onClick={onClick}
      >
        {icon ? (
          <Button
            size="lg"
            aria-label="choice"
            leftIcon={icon}
            w="100%"
            colorScheme={color}
          >
            <GameText text={props.action.title} />
          </Button>
        ) : (
          <Button size="lg" aria-label="choice" w="100%" colorScheme={color}>
            <GameText text={props.action.title} />
          </Button>
        )}
      </motion.a>
    </Box>
  )
}

export default SlideViewer

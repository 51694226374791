import { Box, Flex } from "@chakra-ui/react"

import { AnimatePresence } from "motion/react"
import { useAppSelector } from "../../app/hooks"
import { selectCharacterAvatar } from "./characterSlice"
import { useEffect, useState } from "react"

export const CharacterAvatar: React.FC = () => {
  const avatarDataLoader = useAppSelector(selectCharacterAvatar)
  const [avatarData, setAvatarData] = useState(avatarDataLoader)

  useEffect(() => {
    setAvatarData(avatarDataLoader as any)
  }, [avatarDataLoader])

  return (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="center">
         <Box
         m={8}
          aspectRatio={0.4}
          height="55vh"
          position="relative"
          overflow="hidden"
          boxShadow="md"
          borderRadius="full"
        >
          <img
            src="/character/skin-medium.png"
            alt="Character Avatar"
            style={{
              height: "55vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />

          <img
            src="/character/outline.png"
            alt="Character Avatar"
            style={{
              height: "55vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          <img
            src="/character/skin-medium.png"
            alt="Character Avatar"
            style={{
              height: "55vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          <img
            src="/character/eyes-neutral.png"
            alt="Character Avatar"
            style={{
              height: "55vh",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          {Object.entries(avatarData).map(([key, value]) => (
            <AnimatedAvatarLayer key={key} name={key} src={value} />
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

const AnimatedAvatarLayer: React.FC<{
  name: string
  src: string
}> = ({ src, name }) => {
  return (
    <Box position="relative">
      <AnimatePresence>
        <img
          key={name}
          src={src}
          alt=""
          style={{
            height: "55vh",
            objectFit: "cover",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </AnimatePresence>
    </Box>
  )
}

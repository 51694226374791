import { FaHeart } from "react-icons/fa"
import { useAppSelector } from "../../app/hooks"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameHealth } from "./gameSlice"
import { Icon } from "@chakra-ui/react"

export const HealthMeter = () => {
  const turnHealth = useAppSelector(selectGameHealth)
  const { healthModifier } = useGameTriggerContext()

  return (
    <MeterBar
      label="Health"
      value={turnHealth}
      max={100}
      icon={<Icon mt={2} boxSize={8} as={FaHeart} />}
      modifier={healthModifier}
    />
  )
}

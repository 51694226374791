// src/context/GameTriggerContext.tsx
import type { ReactNode } from "react"

import { createContext, useContext, useState } from "react"

// Define the types for the context
interface GameTriggerContextType {
  healthModifier: number
  setHealthModifier: React.Dispatch<React.SetStateAction<number>>
  educationModifier: number
  setEducationModifier: React.Dispatch<React.SetStateAction<number>>
  relationshipModifier: number
  setRelationshipModifier: React.Dispatch<React.SetStateAction<number>>
  agencyModifier: number
  setAgencyModifier: React.Dispatch<React.SetStateAction<number>>
}

// Create the context with a default value (can be null initially)
const GameTriggerContext = createContext<GameTriggerContextType | undefined>(
  undefined,
)

// Create a provider component
interface GameProviderProps {
  children: ReactNode
}

export const GameTriggerProvider: React.FC<GameProviderProps> = ({
  children,
}) => {
  const [healthModifier, setHealthModifier] = useState<number>(0)
  const [educationModifier, setEducationModifier] = useState<number>(0)
  const [relationshipModifier, setRelationshipModifier] = useState<number>(0)
  const [agencyModifier, setAgencyModifier] = useState<number>(0)

  return (
    <GameTriggerContext.Provider
      value={{
        healthModifier,
        setHealthModifier,
        educationModifier,
        setEducationModifier,
        relationshipModifier,
        setRelationshipModifier,
        agencyModifier,
        setAgencyModifier,
      }}
    >
      {children}
    </GameTriggerContext.Provider>
  )
}

// Custom hook to use the GameTriggerContext
export const useGameTriggerContext = (): GameTriggerContextType => {
  const context = useContext(GameTriggerContext)
  if (!context) {
    throw new Error("useGameTriggerContext must be used within a GameProvider")
  }
  return context
}

import type { PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "../../app/createAppSlice"
import type { GameLogicCharacter } from "@types"
import { select } from "framer-motion/client"

// Load default from JSON
export const characterInitialState: GameLogicCharacter = {
  name: "Your Name Here",
  avatar: {
    eyebrowsLayer: "/character/eyebrows-neutral.png",
    hairLayer: "/character/hair-messy-locs.png",
    glassesLayer: "/character/glasses-reading.png",
    faceLayer: "/character/face-neutral.png",
    chestLayer: "/character/outfit-casual-skirt.png",
  },
}

export const AvatarOptionsData = {
  outlineLayer: ["/character/outline.png"],
  hairLayer: [
    "/character/hair-messy-locs.png",
    "/character/hair-short-fro.png",
    "/character/defaultEmpty.svg",
  ],
  eyebrowsLayer: [
    "/character/eyebrows-neutral.png",
    "/character/eyebrows-quizzical.png",
    "/character/defaultEmpty.svg",
  ],
  glassesLayer: [
    "/character/glasses-reading.png",
    "/character/defaultEmpty.svg",
  ],
  faceLayer: ["/character/face-neutral.png", "/character/face-smile.png"],
  chestLayer: [
    "/character/outfit-casual-skirt.png",
    "/character/outfit-catholic-sweater.png",
  ],
}

export const characterSlice = createAppSlice({
  name: "character",
  initialState: characterInitialState,
  reducers: create => ({
    updateCharacterAvatar: create.reducer(
      (state, action: PayloadAction<GameLogicCharacter["avatar"]>) => {
        state.avatar = action.payload
      },
    ),
    updateCharacterName: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.name = action.payload
      },
    ),
  }),

  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    // State
    selectCharacterAvatar: character => character.avatar,
    selectCharacterName: character => character.name,
  },
})

// Action creators are generated for each case reducer function.
export const { updateCharacterAvatar, updateCharacterName } =
  characterSlice.actions

// Selectors regameed by `slice.selectors` take the root state as their first argument.
export const { selectCharacterAvatar, selectCharacterName } =
  characterSlice.selectors

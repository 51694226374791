import { Icon } from "@chakra-ui/react"
import { useAppSelector } from "../../app/hooks"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameEducation } from "./gameSlice"
import { FaBook } from "react-icons/fa"

export const EducationMeter = () => {
  const turnEducation = useAppSelector(selectGameEducation)
  const { educationModifier } = useGameTriggerContext()

  return (
    <MeterBar
      label="Education"
      value={turnEducation}
      max={100}
      icon={<Icon mt={2} boxSize={8} as={FaBook} />}
      modifier={educationModifier}
    />
  )
}

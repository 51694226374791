import type { GameLogicContent, GameLogicStatic, SlideTypeEvent } from "@types"
import defaultGameData from "../defaultGameData"

// Templates Strings
// Stats {$age}, {$choices}, {$duration}
// Meters {$education}, {$relationship}, {$health}\

const GAMEDATA: GameLogicStatic = {
  rules: {
    multipler: 2,
    age: { lt: 24 },
    agency: { gt: 0, lt: 100 },
    education: { gt: 0, lt: 100 },
    relationship: { gt: 0, lt: 100 },
    health: { gt: 0, lt: 100 },
  },
  start: {
    turn: 0,
    duration: 0,
    age: 0,
    agency: 25,
    education: 25,
    relationship: 25,
    health: 25,
    history: [],
    slide: {
      title: { en: "Welcome to My Choices" },
      description: { en: "Press Continue" },
      image: "/default_slide.webp",
      imageAlt: "slide alt text",
      type: "choice",
      selections: [
        {
          title: { en: "Continue" },
          icon: "positive",
        },
      ],
    },
    tags: [],
    effects: {
      age: 1,
    },
  },
  content: defaultGameData as GameLogicContent,
}

export default GAMEDATA
